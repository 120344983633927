@import "../../../../variables.css";

.textButton {
    background-color: transparent;
    border: none;
    box-shadow: none;
    cursor: pointer;
    padding: 0;
}

.caption {
    color: var(--ark-tertiary-color);
    font-size: var(--ark-26-font-size);
    line-height: var(--ark-32-line-height);
    font-weight: var(--ark-font-weight-bold);
    transition: color 0.166s linear;
}

.textButton:hover .caption {
    color: var(--ark-primary-blue-figma);
}
