@import "../../../variables.css";

.breadcrumbsBlock a {
    text-decoration: none;
}

.breadcrumbsBlock span:not(:last-child) a {
    opacity: 0.6;
}

.breadcrumbsBlock span:not(:last-child) a:hover {
    opacity: 1;
}

.breadcrumbsBlock span {
    font-weight: var(--ark-font-weight-bold);
    color: var(--ark-neutral-white);
    font-size: var(--ark-18-font-size);
}

.breadcrumb {
    display: inline-flex;
}

.breadcrumbSeparator {
    display: inline-flex;
    margin: 0 12px;
    font-size: 1.25rem;
    color: var(--ark-neutral-white);
    font-weight: var(--ark-font-weight-bold);
}

.breadcrumbSeparator:last-child {
    display: none;
}

/* On Light background styles based on Figma Design system*/

.breadcrumbsBlock.onLightBg span{
    color: var(--ark-primary-royal-blue-figma);
}

.breadcrumbsBlock.onLightBg span a{
    color: var(--ark-secondary-solid-midgrey);
}

.breadcrumbsBlock.onLightBg span a:hover span{
    color: var(--ark-hover-dark-blue-figma);
}

.breadcrumbsBlock .separatorOnLight{
    color: var(--ark-hover-light-blue-figma)
}

