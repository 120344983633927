@import '../../../variables.css';

.headerAssets {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    justify-content: space-between;
    z-index: 1;
}

.headerGameLeftAsset,
.headerGameRightAsset {
    width: 100%;
    height: auto;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: right;
}

.pageGame .headerGameLeftAsset,
.pageGame .headerGameRightAsset {
    width: 600px;
    height: 270px;
}

.headerGameLeftAsset {
    background-position: left bottom;
}

.headerGameRightAsset {
    background-position: right top;
}

@media screen and (max-width: var(--ark-small-desktop-figma-1024)) {
    .pageGame .headerGameRightAsset {
        display: none;
    }
}
