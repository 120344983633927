@import '../../../variables.css';

.container{
    display: flex;
    flex-direction: column;
    gap: 20px;
    text-align: center;
    margin: 10% 0;
}

.title {
    font-weight: var(--ark-font-weight-bold);
    font-size: var(--ark-24-font-weight);
    line-height: var(--ark-16-line-height);
}