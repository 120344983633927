@import '../../../variables.css';

.descriptionBlock {
}

.tabHeader {
    background-color: var(--ark-gray-extra-light-color);
    border-top-right-radius: 0.625rem;
    border-top-left-radius: 0.625rem;
    display: flex;
    padding-left: var(--ark-spacing-l);
    padding-right: var(--ark-spacing-l);
}

.descriptionText {
    padding: 1rem 1.5rem 1.5rem;
    font-size: var(--ark-20-font-size);
    line-height: var(--ark-32-line-height);
    letter-spacing: normal;
    color: var(--ark-neutral-black);
    min-height: 100px;
}

.descriptionText b {
    font-weight: bold;
}

.descriptionText a {
    text-decoration: none;
    word-break: break-word;
}

.descriptionText h2 {
    font-size: var(--ark-26-font-size);
    line-height: var(--ark-32-line-height);
    font-weight: var(--ark-font-weight-bold);
    padding-bottom: 0.625rem;
}

.descriptionText h3 {
    font-size: var(--ark-24-font-size);
    line-height: var(--ark-h3-line-height);
    font-weight: var(--ark-font-weight-bold);
    padding-bottom: 0.625rem;
}

.descriptionText h4 {
    font-size: var(--ark-22-font-size);
    line-height: var(--ark-32-line-height);
    font-weight: var(--ark-font-weight-bold);
    padding-bottom: 0.625rem;
}

.descriptionText p {
    padding-bottom: 0.625rem;
    font-weight: var(--ark-font-weight-regular);
    color: var(--ark-black-color);
}

.textItem {
    display: none;
}

.activeTextItem {
    display: block;
}

.skeletonHeader {
    border-top-left-radius: 1.25rem;
    border-top-right-radius: 1.25rem;
    background: var(--ark-gray-light2-color);
    height: 4.5rem;
    width: 100%;
    margin-bottom: 1.5rem;
}

.skeletonContent {
    background: var(--ark-gray-light2-color);
    width: 100%;
    height: 25rem;
}

.descriptionText li {
    list-style: initial;
    margin-left: 1.3rem;
    font-weight: var(--ark-font-weight-regular);
}

.descriptionText .comments-tabpanel li {
    list-style: none;
}

.descriptionText ul {
    padding-bottom: var(--ark-spacing-s);
}

.categoryPageText {
    padding: 0 0.75rem;
}

.gamePageText {
    padding-left: var(--ark-spacing-l);
    margin-left: 0.313rem;
}

/* width: 0 - 1024px */

@media (max-width: var(--ark-small-desktop-figma-1024)) {
    .skeletonHeader {
        margin-bottom: 0.5rem;
    }

    .skeletonContent {
        height: 12.5rem;
    }

    .descriptionText {
        padding-left: var(--ark-spacing-s);
        padding-right: var(--ark-spacing-xxs);
    }

    .descriptionText li {
        margin-left: var(--ark-spacing-s);
    }

    .descriptionText ul {
        padding-bottom: var(--ark-spacing-xxs);
    }
}

/* width: 0 - 767px */

@media (max-width: var(--ark-large-mobile-below-767)) {
    .tabHeader {
        padding-left: 0;
        padding-right: 0;
    }

    .descriptionText {
        padding-bottom: 0;
    }

    .descriptionText p {
        font-size: var(--ark-14-font-size);
        line-height: 1.375rem;
    }

    .descriptionText li {
        font-size: var(--ark-14-font-size);
        line-height: 1.375rem;
    }

    .descriptionText h2,
    .descriptionText h3 {
        font-size: var(--ark-16-font-size);
        line-height: var(--ark-18-line-height);
    }

    .descriptionText h4 {
        font-size: var(--ark-14-font-size);
        line-height: var(--ark-18-line-height);
    }
}
