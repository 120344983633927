@import '../../../variables.css';

:root {
    --menu-item-height: 56px;
    --menu-item-padding-left: 35px;
}

.sideBar {
    display: flex;
    flex-direction: column;
    width: var(--category-page-sidebar-width);
    background-color: var(--ark-neutral-white);
    box-shadow: 2px 2px 4px 0 var(--ark-black-15-color);
    min-height: 400px;
}

.scrollableContainer {
    overflow-y: auto;
}

.menuItemButton {
    width: 100%;
}

.menuItemText {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding-left: var(--menu-item-padding-left);
    height: var(--menu-item-height);
    font-size: var(--ark-24-font-size) !important;
    color: var(--ark-black-color) !important;
    font-weight: var(--ark-font-weight-bold) !important;
}

.menuItemText:hover {
    color: #0042bb !important;
}

.activeMenuItem {
    background-color: var(--ark-primary-20-color);
}
