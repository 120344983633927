@import '../../../variables.css';


.wrapper {
    position: relative;
    width: 100%;
    min-height: 165px;
    overflow: hidden;
    padding: 0 30px;
    margin-top: 30px;
}

.wrapper .bannerContainer {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: radial-gradient(53.34% 487.27% at 50% 50%, rgba(0, 34, 255, 0.5) 34.55%, rgba(9, 36, 86, 0) 99.17%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
    #1e2a79;
    border-radius: var(--ark-border-radius);
}

@media (max-width: var(--ark-small-desktop-figma-1024)) {
    .wrapper .bannerContainer {
        display: block;
        padding: 32px 16px;
        text-align: center;
    }
}

.wrapper .textContainer {
    height: auto;
    width: auto;
    margin-right: 65px;
}

@media (max-width: var(--ark-small-desktop-figma-1024)) {
    .wrapper .textContainer {
        margin-right: 0;
    }
}

.wrapper .title {
    font-size: var(--ark-36-font-size);
    font-weight: var(--ark-font-weight-bold);
    line-height: 33.6px;
    color: var(--ark-primary-white-figma);
    margin-bottom: 8px;
}

@media (max-width: var(--ark-medium-desktop-figma-below-1359)) {
    .wrapper .title {
        font-size: var(--ark-28-font-size);
    }
}

@media (max-width: var(--ark-small-desktop-figma-1024)) {
    .wrapper .title {
        font-size: var(--ark-24-font-size);
    }
}

.wrapper .subTitle {
    font-size: var(--ark-18-font-size);
    color: var(--ark-primary-white-figma);
    line-height: 25.2px;

}

@media (max-width: var(--ark-medium-desktop-figma-below-1359)) {
    .wrapper .subTitle {
        font-size: var(--ark-16-font-size);
    }
}

.wrapper .button {
    min-width: 150px;
}

@media (max-width: var(--ark-small-desktop-figma-1024)) {
    .wrapper .button {
        margin: 20px auto 0 auto;
    }
}

.wrapper .white {
    background-color: var(--ark-primary-white-figma);
    color: var(--ark-primary-black-figma);
}
