@import '../../../variables.css';

.homepageSlider {
    width: 100%;
}

.captionAndLink {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
}

.gameAreaRecommendedGamesContainer .captionAndLink {
    margin-bottom: 1rem;
}

[data-variation='C'] .captionAndLink {
    display: flex;
    justify-content: initial;
    margin-top: 40px;
}

[data-variation='C'] .caption {
    margin-right: 20px;
}

[data-variation='C'] .caption a {
    color: var(--ark-primary-black-figma);
    font-size: var(--ark-36-font-size);
}

.caption {
    font-size: var(--ark-32-font-size);
    font-weight: var(--ark-font-weight-bold);
    line-height: var(--ark-40-line-height);
    color: var(--ark-gray-medium-color);
}

.caption a {
    color: var(--ark-tertiary-color);
    text-decoration: none;
}

.caption a:hover {
    color: var(--ark-primary-blue-figma);
}

.smallButton {
    top: calc(7.5rem / 2);
}

.mediumButton {
    top: calc(12.875rem / 2);
}

.largeButton {
    top: calc(17.5rem / 2);
}

.superLargeButton {
    top: calc(37.5rem / 2);
}

.longButton {
    top: calc(13.5rem / 2) !important;
}

[data-variation='C'] .button {
    display: flex;
    width: 142px;
    height: 42px;
    padding: 4px 10px 4px 20px;
    border-width: 3px;
    gap: 3px;
}

[data-variation='C'] .button span {
}

@media (max-width: var(--ark-small-desktop-figma-1024)) {
    .caption {
        font-size: var(--ark-22-font-size);
        font-weight: var(--ark-font-weight-bold);
        line-height: var(--ark-32-line-height);
        padding-left: 20px;
    }

    .captionAndLink {
        padding-right: 1.25rem;
        margin-bottom: 0;
        min-height: 48px;
        align-items: center;
    }

    .seeAllButton {
        font-size: var(--ark-20-font-size) !important;
        font-weight: var(--ark-font-weight-bold) !important;
        line-height: var(--ark-32-line-height) !important;
    }
}
