@import "../../../variables.css";

.page {
    display: block;
    background-color: #12d9b8;
    height: 800px;
}

.content {
    display: flex;
    align-items: center;
    height: 100%;
}

.pageContainer {
    margin: 0 auto;
    height: 100%;
}

.homepageLink {
    display: inline-block;
    vertical-align: middle;
    text-decoration: none;
    transform: translateZ(0);
    backface-visibility: hidden;
    position: relative;
    font-size: 20px;
    color: #fff;
    background-color: #e4010c;
    padding: 14px 31px 14px 31px;
    font-weight: 100;
    margin-top: 60px;
}

.homepageLink::before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #4d4d4d;
    transform: scaleX(0);
    transform-origin: 0 50%;
    transition: transform .3s ease-out;
}

.homepageLink:hover::before {
    transform: scaleX(1);
}

.header {
    font-weight: 100;
    color: #fff;
    margin-bottom: 0;
    font-size: 2rem;
}

.subHeader {
    font-weight: 100;
    color: #fff;
    margin-top: 0;
    margin-bottom: 0;
    font-size: 1.25rem;
    line-height: 2rem;
}

.text {
    line-height: 1.67;
    font-size: 14px;
    font-weight: 400;
    margin-top: 1rem;
    color: #fff;
}

@media (min-width: 768px) {
    .pageContainer {
        max-width: 750px;
    }

    .h1 {
        font-size: 48px;
    }

    .h4 {
        font-size: 24px;
    }

    .text {
        font-size: 18px;
    }
}

@media (min-width: 992px) {
    .pageContainer {
        max-width: 970px;
    }

    .content {
        width: 50%;
    }
}

@media (min-width: 1200px) {
    .pageContainer {
        max-width: 1170px;
    }
}
