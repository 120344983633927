@import '../../../variables.css';

.adRowDesktop {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 3rem;
    margin-bottom: 3.5rem;
}

.adRowDesktop.firstItem {
    margin-top: 0.5rem;
    margin-bottom: 2.5rem;
    padding-left: 0.25rem;
    padding-right: 0.25rem;
}

.adRowMobile {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 3rem;
    margin-bottom: 3rem;
}

.adRowMobile.firstItem {
    margin-top: 1rem;
    margin-bottom: 2.5rem;
    padding-left: 0.25rem;
    padding-right: 0.25rem;
}

.adRowMobile.lastItem {
    margin-bottom: 0;
}

.adContainer {
    flex-shrink: 0;
    min-height: 8.25rem;
}

.allCategoriesAdContainer {
    padding-top: 0.5rem;
    padding-bottom: 2rem;
}

.gamesContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
}

.list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: -2.5rem;
    margin-left: -1rem;
    padding-left: 1.75rem;
    padding-right: 1.75rem;
    width: calc(100% + 1rem);
}

.allCategoriesList {
}

.listItem {
    margin-top: 2.5rem;
    margin-left: 1rem;
}

.freeVersionList {
    margin-top: 0;
}

@media (max-width: var(--ark-small-desktop-figma-1024)) {
    .list {
        padding-left: 1.25rem;
        padding-right: 1.25rem;
        margin-top: 1.5rem;
    }

    .freeVersionList {
        margin-top: 0;
    }

    .allCategoriesList {
        padding-top: 2rem;
    }

    .listItem {
        margin-top: 1.5rem;
        margin-left: 1rem;
    }

    .allCategoriesAdContainer {
        display: none;
    }
}

@media (max-width: var(--ark-large-mobile-below-767)) {
    .contentContainer {
        padding-top: 1.5rem;
    }

    .list {
        margin-left: -0.625rem;
    }

    .freeVersionList {
        margin-top: 0;
    }

    .listItem {
        margin-left: 0.625rem;
    }
}

@media (max-width: var(--ark-small-mobile-below-575)) {
    .adRowMobile {
        margin-top: 2.5rem;
    }
}

@media (max-width: var(--ark-small-mobile-below-575)) {
    .adRowMobile {
        margin-top: 2.5rem;
    }
}
