@import '../../../variables.css';

* {
    box-sizing: border-box;
}

.wrapper {
    position: relative;
    width: 100%;
    margin: 0 2rem;
}

.content {
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.row {
    display: flex;
    flex-flow: column;
    margin-bottom: 15px;
}

.rowContent {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
}

.adContainer {
    margin-top: 20px;
}

.listItems {
    display: flex;
    width: 100%;
    flex-flow: row wrap;
    justify-content: space-between;
}

.item {
}

.header {
    position: relative;
    display: flex;
    margin-bottom: 15px;
}

.caption {
    font-size: var(--ark-28-font-size);
    font-weight: var(--ark-font-weight-bold);
    line-height: var(--ark-32-line-height);
    color: var(--ark-primary-black-figma);
    margin-right: 20px;
}

.caption a {
    color: var(--ark-primary-black-figma);
    text-decoration: none;
}

.caption a:hover {
    color: var(--ark-primary-blue-figma);
}

@media (max-width: var(--ark-small-desktop-figma-1024)) {
    .wrapper {
        width: 500px;
        margin: 0 auto;
    }

    .content {
        width: 100%;
        overflow: visible;
    }

    .main {
        padding-left: 0;
    }

    .row {
        margin-bottom: 2.5rem !important;
    }
}

@media (max-width: var(--ark-medium-mobile-figma)) {
    .row {
        display: flex;
    }
}

@media (max-width: var(--ark-small-mobile-below-575)) {
    .wrapper {
        width: 330px;
    }
}

@media (max-width: var(--ark-new-med-small-mobile-360)) {
    .wrapper {
        margin: 0 10px;
        width: auto;
    }

    .listItems {
        gap: 10px;
    }
}

@media (max-width: 345px) {
    .listItems {
        gap: 7px;
        justify-content: space-evenly;
    }
}
