@import '../../../variables.css';

:root {
    --content-max-width: 1800px;

    --content-padding-mobile: 20px;

    --description-padding-top-desktop: 40px;
    --description-padding-right-desktop: 44px;
    --description-padding-left-desktop: 40px;
    --description-padding-bottom-desktop: 64px;
    --description-padding-top-mobile: 20px;
    --description-padding-top-middle-size: 48px;
}

.pageContainer {
    margin-top: 0;
    position: relative;
    overflow: hidden;
    /* background-color: rgba(203, 209, 209, 1); */
}

.contentWrapper {
    /* background-color: rgb(99, 163, 189); */
    display: flex;
    width: 100%;
}

.sideBarContainer {
    position: relative;
    width: var(--category-page-sidebar-width);
    /* background-color: rgb(150, 150, 190);     */
}

.noGamesContainer {
}

.noGamesText {
    padding: var(--ark-spacing-xxl-2);
    padding-bottom: 16rem;
    font-size: var(--ark-32-font-size);
}

.pageContentContainer {
    position: relative;
    /* background-color: rgb(64, 163, 64); */
    display: flex;
    flex-direction: column;
    z-index: 1;
    overflow: hidden;
    min-width: calc(100vw - var(--category-page-sidebar-width, 276px));
}

.skeletonConainer {
    position: absolute;
}

.tilesContainer {
    /* background-color: rgb(140, 187, 140); */
    height: auto;
    overflow: visible;
}

.tilesContainer.bottomPadding {
    padding-bottom: 4rem;
}

.descriptionContainer {
    /* background-color: rgb(240, 208, 25); */
    padding-top: var(--description-padding-top-desktop);
    padding-bottom: var(--description-padding-bottom-desktop);
    padding-left: var(--description-padding-left-desktop);
    height: auto;
    overflow: visible;
}

.expandContent {
    height: auto;
}

.collapseContent {
    /*height: 0;
    overflow: hidden;
    padding: 0 !important;*/
}

/* DESKTOP QUERIES */

@media (min-width: var(--ark-small-desktop-1025)) and (max-width: var(--ark-large-desktop-above-1801)) {
    .descriptionContainer {
        padding-right: var(--description-padding-right-desktop);
    }
}

@media (min-width: 1025px) {
    .skeletonConainer {
        display: none;
    }
}

/* MOBILE QUERIES */
@media (min-width: var(--ark-large-mobile-768)) and (max-width: var(--ark-small-desktop-figma-1024)) {
    .descriptionContainer {
        padding-top: var(--description-padding-top-middle-size);
        padding-left: 0;
    }
}

@media (max-width: var(--ark-large-mobile-below-767)) {
    .descriptionContainer {
        padding-top: var(--description-padding-top-mobile);
        padding-left: 0;
    }
}

@media (max-width: var(--ark-small-desktop-figma-1024)) {
    .mobileHidden {
        display: none;
    }
    .pageContainer {
        margin-top: 0;
    }
}
